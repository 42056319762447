import { render, staticRenderFns } from "./tournaments-list-skeleton.vue?vue&type=template&id=f037d190&scoped=true&"
import script from "./tournaments-list-skeleton.vue?vue&type=script&lang=js&"
export * from "./tournaments-list-skeleton.vue?vue&type=script&lang=js&"
import style0 from "./tournaments-list-skeleton.vue?vue&type=style&index=0&id=f037d190&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f037d190",
  null
  
)

export default component.exports