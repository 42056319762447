<template>
  <div class="page-wrapper">
    <div class="page-title">Все турниры</div>
    <div class="tournaments__wrapper" v-if="!isPageDataLoad">
      <div ref="tournamentsBody" class="tournaments__body">

        <tournaments-slider v-if="dataReady" :groups="GET_TOURNAMENT_GROUPS"/>
        <slider-skeleton v-else/>

        <div v-if="!listInProgress">
          <tournaments-list/>
        </div>
        <tournaments-list-skeleton v-else/>
        <div v-if="!TOURNAMENTS.length && !IS_TOURNAMENTS_IN_REQUEST" class="empty">К сожалению, список турниров пуст.
          Приходите позже :(
        </div>

      </div>
      <media :query="{minWidth: 1025}">
        <div class="tournaments__parameters">
          <div class="amount">
            Количество турниров:<span>{{ getAllCountOfTournaments }}</span>
          </div>
          <div class="filters-wrapper">
            <div class="filters-label">Выберите игру</div>
            <div class="filters-group">
              <select-dropdown :options="options" @input="filteringByGame" :initial="defaultFilters"
                               :option_id="option_id"/>
            </div>
            <div class="filters-button" @click="resetFilters">Сбросить фильтр</div>
          </div>
        </div>
      </media>
    </div>
    <tournaments-skeleton v-else/>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import Media from 'vue-media';
import SelectDropdown from "@/components/UIElements/selectDropdown";

import TournamentsSkeleton from "@/views/sceleton/tournaments-skeleton";
import TournamentsListSkeleton from "@/views/sceleton/tournaments-list-skeleton";
import TournamentsList from "@/components/tournaments/tournamentsList";
import TournamentsSlider from "@/components/tournaments/tournamentsSlider";
import SliderSkeleton from "./sceleton/slider-skeleton";
import _ from "lodash";
import {ActiveEcho as PublicEcho} from "@/main";

export default {
  name: "allTournaments",
  components: {
    SliderSkeleton,
    TournamentsSlider,
    TournamentsList,
    TournamentsListSkeleton,
    TournamentsSkeleton,
    SelectDropdown,
    Media
  },
  data() {
    return {
      dataReady: false,
      filtersOpen: false,
      page_number: 1,
      page_size: 15,
      listInProgress: false,
      isPageDataLoad: true,
      options: [{
        id: 0,
        name: 'Все'
      }],
      defaultFilters: false,
      option_id: null,
      observer: null
    };
  },
  computed: {
    ...mapState({
      sponsorGames: store => store.disciplines.sponsorGames
    }),
    ...mapGetters([
      'TOURNAMENTS',
      'IS_TOURNAMENTS_IN_REQUEST',
      'GET_TOURNAMENTS_META',
      'GET_TOURNAMENT_GROUPS'
    ]),

    getAllCountOfTournaments() {
      if (!this.GET_TOURNAMENTS_META) return '';
      return this.GET_TOURNAMENTS_META.page.total;
    }
  },
  methods: {
    ...mapActions([
      'GET_TOURNAMENTS',
      'GET_DISCIPLINES',
      'FILTERING_BY_GAME',
      'SET_TOURNAMENTS_REQUEST',
      'SET_TOURNAMENT_GROUPS'
    ]),
    ...mapMutations([
      'SET_TOURNAMENTS_LIST_FILTER'
    ]),
    filteringByGame(option) {
      this.$refs.tournamentsBody.scrollIntoView({block: 'start', behavior: 'smooth'});
      this.listInProgress = true;
      this.option_id = option.id;

      this.SET_TOURNAMENTS_LIST_FILTER({game_id: option.id});

      this.GET_TOURNAMENTS().then(() => {
        this.listInProgress = false;
        this.defaultFilters = false;
      });
    },
    setGamesFilter() {
      this.options = this.sponsorGames.map(item => {
        return {
          id: item.id,
          type: item.type,
          name: item.attributes.name
        };
      });
      this.options.unshift({
        id: 0,
        name: 'Все'
      });
    },
    resetFilters() {
      this.SET_TOURNAMENTS_LIST_FILTER({game_id: null});
      this.$refs.tournamentsBody.scrollIntoView({block: 'start', behavior: 'smooth'});
      this.option_id = null;
      this.listInProgress = true;

      this.defaultFilters = true;
      this.GET_TOURNAMENTS().then(() => {
        this.listInProgress = false;
      });
    },
    initGroups() {
      this.dataReady = false;
      this.SET_TOURNAMENT_GROUPS().then(() => {
        this.dataReady = true;
      });
    },

    /**
     * Обновляем какой-то 1 турнир, не чаще 1 раза в 1 минуту
     * */
    updateTournamentInList: _.debounce(function (ev) {
      this.UPDATE_TOURNAMENT_IN_LIST(ev.tournament);
    }, 60000, {'leading': true, 'trailing': false}),

    /**
     * Подписываемся на обновления списка турниров
     * */
    subscribeToTournamentsUpdate() {
      PublicEcho.channel('tournaments')
          .listen('.TournamentChanged', ev => {
            this.updateTournamentInList(ev);
          });
    }
  },
  async created() {
    if (this.$route.params.game_id !== undefined) {

      this.SET_TOURNAMENTS_REQUEST(true);
      this.listInProgress = true;

      this.setGamesFilter();

      this.SET_TOURNAMENTS_LIST_FILTER({game_id: this.$route.params.game_id});

      await this.GET_TOURNAMENTS();
      this.option_id = this.$route.params.game_id;
      this.listInProgress = false;
      this.defaultFilters = false;

      await this.SET_TOURNAMENTS_REQUEST(false);

    } else {

      this.SET_TOURNAMENTS_REQUEST(true);
      this.listInProgress = true;
      await this.GET_TOURNAMENTS();
      this.setGamesFilter();

      this.SET_TOURNAMENTS_REQUEST(false);
      this.listInProgress = false;

    }

    this.isPageDataLoad = false;
  },
  mounted() {
    this.initGroups();
    this.subscribeToTournamentsUpdate();
  },
  beforeDestroy() {
    PublicEcho.leave('tournaments');
  }
};
</script>

<style scoped lang="scss">
@import "src/scss/vars/mixins";

.sort-options {
  .custom-select {
    width: 186px;
  }
}

.page-wrapper {
  margin-top: 64px;
}

.empty {
  color: white;
  font-family: $blatant-cyrillic;
  font-size: 24px;
}
</style>
