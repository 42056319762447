<template>
  <div>
    <div class="tournaments__list">
      <skelet-rectangle :width="10000" :height="70" />
      <skelet-rectangle :width="10000" :height="70" />
      <skelet-rectangle :width="10000" :height="70" />
      <skelet-rectangle :width="10000" :height="70" />
      <skelet-rectangle :width="10000" :height="70" />
      <skelet-rectangle :width="10000" :height="70" />
      <skelet-rectangle :width="10000" :height="70" />
      <skelet-rectangle :width="10000" :height="70" />
      <skelet-rectangle :width="10000" :height="70" />
      <skelet-rectangle :width="10000" :height="70" />
    </div>
  </div>
</template>


<script>
import SkeletRectangle from "@/views/sceleton/components/rectangle";

export default {
  name: "tournaments-list-skeleton",
  components: {SkeletRectangle},
}
</script>

<style lang="scss" scoped>
  .rect {
    margin-bottom: 4px;
  }
</style>
