<template>
  <div class="tournaments-slider">
    <swiper ref="mySwiper" :options="swiperOption">
      <swiper-slide class="swiper-slide" :key="group.id" v-for="group in groups">
        <router-link :to="{name: 'sgl-events', params: {id: group.id}}" class="group-slide">
          <img class="group-slide--bg" :src="group.background_url" alt="Not found">
          <div class="group-slide--content">
            <!--<div class="group-slide&#45;&#45;partners">
                  <img v-for="image in 2" :key="image.id" :src="require('@/img/partners-logo/logo2.png')" :alt="'Not found'"/>
               </div>-->
            <div class="group-slide--top">
              <div class="sgl-label sgl-label--colored">группа турниров</div>
              <div class="title">{{ group.attributes.name }}</div>
            </div>
            <div class="group-slide--bot">
              <img :src="getLogoOfGame(group)" alt="Not found">
              <!--TODO Добавить лого платформы как на бэке появится-->
              <!--<img :src="require('@/img/disciplines-logo/fifa.png')" alt="Not found">-->
            </div>
          </div>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "tournamentsSlider",
  data() {
    return {
      swiperOption: {
        centeredSlides: false,
        loop: true,
        slidesPerView: 'auto',
        speed: 500,
      }
    }
  },
  methods: {
    getLogoOfGame(el) {
      return this.GAMES.find(game => String(game.id) === String(el.gameInfo.id)).logo ?? ''
    },
  },
  props: {
    groups: Array
  },
  computed: {
    ...mapGetters([
      'GAMES'
    ]),
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>