<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{open: open}" @click="open = !open">
      {{ selected.name }}
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6L8 10L12 6" stroke="white" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="items" :class="{selectHide: !open}">
      <div
          class="item"
          v-for="(option, i) of options"
          :key="i"
          @click="selected = option; open=false; $emit('input', option);"
          :class="{chosen: selected === option}"
      >{{ option.name }}
        <svg class="ok" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 7.5L7 10L12 5" stroke="#E2FF31" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'selectDropdown',
  props: {
    options: {
      type: Array,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    initial: {
      type: Boolean,
       default: false
    },
    option_id: null
  },
  data() {
    return {
      selected: this.options.length > 0 ? this.options[0] : null,
      open: false,
    };
  },
  watch: {
    initial() {
      if (this.initial === true) {
        this.selected = this.options[0]
      }
    },
    option_id() {
      if (this.option_id !== null) {
        this.selected = this.options.find(item => Number(item.id) === Number(this.option_id))
      }
    }
  },
  mounted() {
    // this.$emit("input", this.selected);
  },
};
</script>

<style scoped lang="scss">
@import "src/scss/vars/colors";

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 32px;
  background-color: $elemBg;
  color: $white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.selected {
  background-color: $elemBg;
  color: $white;
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 8px;

  svg {
    transition: all ease-in-out .2s;
  }
}

.selected.open {
  svg {
    transform: rotatex(180deg);
  }
}

.selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 10px;
  width: 0;
  height: 0;
}

.items {
  color: #ffffff;
  overflow: hidden;
  position: absolute;
  background-color: #3A3A3A;
  left: 0;
  right: 0;
  z-index: 501;
  transition: all ease-in-out .1s;
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  transform: none;
}

.item {
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 501;
  padding-left: 16px;
  padding-right: 8px;

  .ok {
    display: none;
  }

  &.chosen {
    background-color: #4E4E4E;
    color: var(--tournament-color);

    .ok {
      display: block;
    }
  }
}

.item:hover {
  background-color: #4E4E4E;
  color: var(--tournament-color);
}

.selectHide {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateY(-5px);
}
</style>