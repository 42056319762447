<template>
  <div>
    <router-link :to="{name: 'another-profile', params: {id: this.player.id}}" target="_blank" v-if="!player.empty" class="member-card">
      <div class="info">
        <div class="ava">
          <img :src="player.logo" alt="#">
          <svg class="cap-flag" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3" y="5" width="18" height="14" rx="2" fill="#E2FF31"/>
            <path
                d="M11.0446 11.3994L13.9636 8.31641H15.3923L12.0153 11.7891L15.5222 15.6855H13.9977L11.0446 12.3291H10.7233V15.6855H9.54755V8.31641H10.7233V11.3994H11.0446Z"
                fill="#242424"/>
          </svg>
        </div>
        <div class="text">
          <div class="top">
            <country-flag :country="player.attributes.country" size="normal" rounded/>
            <div class="nickname">{{ player.attributes.login }}</div>
          </div>
          <div class="bot">{{ player.attributes.firstName }}&nbsp;{{ player.attributes.lastName }}</div>
        </div>
      </div>
      <div class="status">{{ getRole }}</div>
    </router-link>
    <div v-else class="member-card empty">
      <div class="info">
        <div class="ava"></div>
        <div class="text">
          <div class="top"></div>
          <div class="bot"></div>
        </div>
      </div>
      <div class="status"></div>
    </div>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: "tournament-registration-member-card",
  components: {CountryFlag},
  props: {
    player: {}
  },
  computed: {
    getRole() {
      let role = null;
      switch (this.player.role) {
        case 'capitan':
          role = 'Капитан'
          break;
        case 'player':
          role = 'Игрок'
          break;
        case 'substitute':
          role = 'Резерв'
          break;
        default:
          role = ''
      }

      return role
    }
  }
}
</script>

<style scoped>

</style>