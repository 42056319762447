<template>
  <div class="tournaments-slider">
    <skelet-rectangle :width="100000" :height="300"/>
  </div>
</template>

<script>
import SkeletRectangle from "@/views/sceleton/components/rectangle";

export default {
  name: "slider-skeleton",
  components: {SkeletRectangle},
}
</script>

<style scoped lang="scss">
.tournaments-slider {
  border-radius: 8px;
  overflow: hidden;
}
</style>