<template>
  <div class="tournaments__list">
    <tournament-card :index="index" v-for="(tournament, index) in TOURNAMENTS" :key="tournament.id"/>
    <div class="triggerForScroll" v-if="getShowSpinner">
      <spinner/>
    </div>
  </div>
</template>

<script>
import TournamentCard from "@/components/tournaments/tournamentCard";
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/UIElements/spinner";
import copyGetter from "@/otherJS/VuexHelpers/copyGetter";

export default {
  name: "tournamentsList",
  components: {Spinner, TournamentCard},
  data() {
    return {
      observer: null
    }
  },
  computed: {
    ...mapGetters([
      'TOURNAMENTS',
      'IS_TOURNAMENTS_IN_REQUEST',
      'GET_TOURNAMENTS_META',
      'GET_TOURNAMENTS_CONFIG',
      'GET_TOURNAMENTS_CONFIG_ALL_DATA_IS_LOADED',
      'IS_SHOW_SPINNER'
    ]),

    getShowSpinner() {
      return this.IS_SHOW_SPINNER
    },

    // allDataIsLoaded() {
    //   return !copyGetter(this.GET_TOURNAMENTS_CONFIG_ALL_DATA_IS_LOADED);
    // }
  },
  methods: {
    ...mapActions([
      'SET_MORE_TOURNAMENTS'
    ])
  },

  mounted() {
    if (this.getShowSpinner) {
      const target = document.querySelector('.triggerForScroll');

      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0
      }

      const callback = async (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            await this.SET_MORE_TOURNAMENTS()
          }
        }
      }
      this.observer = new IntersectionObserver(callback, options);
      this.observer.observe(target);
    }
  },

  destroyed() {
    if (this.observer) this.observer.disconnect()
  }
}
</script>

<style lang="scss" scoped>
.tournaments__list {
  .triggerForScroll {
    margin-top: 30px;
  }
}
</style>