<template>
  <div class="tournament-card"
       :class="{premium: isPremium((TOURNAMENTS[index])), 'no-button': !isUserCanDoIt(TOURNAMENTS[index])}">
    <router-link class="outer-wrapper" :to="{ name: 'tournaments_id', params: { id: TOURNAMENTS[index].id }}"
                 exact-active-class=""
                 active-class="">
      <div class="tournament-card__head">
        <div class="card-countdown onCD">
          <div class="cd" v-if="isStartedByTime((TOURNAMENTS[index])) > 0">
            <div class="label">Начнётся</div>
            <time class="date">{{getTournamentStartedAtDate(TOURNAMENTS[index])}}</time>
            <time class="time">В {{ getTournamentStartedAtTime(TOURNAMENTS[index]) }} {{ getTimezone() }}</time>
          </div>
          <div class="hasBegun" v-else>{{ getTournamentStatus((TOURNAMENTS[index])) }}</div>
        </div>
        <div class="card-name">
          <div class="card-name__s1">{{ TOURNAMENTS[index].attributes.name }}</div>

          <div class="card-name__s2" v-if="isSponsor">
            {{ TOURNAMENTS[index].attributes.partner }}
            <svg v-if="TOURNAMENTS[index].attributes.sponsorship" width="16" height="17" viewBox="0 0 16 17"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.65644 2.31807L8 0.5L6.34356 2.31807L4 1.5718L3.47452 3.97452L1.0718 4.5L1.81807 6.84356L0 8.5L1.81807 10.1564L1.0718 12.5L3.47452 13.0255L4 15.4282L6.34356 14.6819L8 16.5L9.65644 14.6819L12 15.4282L12.5255 13.0255L14.9282 12.5L14.1819 10.1564L16 8.5L14.1819 6.84356L14.9282 4.5L12.5255 3.97452L12 1.5718L9.65644 2.31807ZM7.33922 10.8674L11.6726 6.8674L10.9943 6.1326L7.01394 9.80675L5.0203 7.81311L4.31319 8.52022L6.64653 10.8536L6.98622 11.1932L7.33922 10.8674Z"
                    fill="#E2FF31"/>
            </svg>
          </div>
        </div>
        <div class="card-info_wrapper">
          <div class="tournament-status">{{ TOURNAMENTS[index].attributes.prize_fund }}</div>
          <div class="prize-pool">
            <span class="amount">{{  }}</span>
          </div>
          <div class="teams">
            <span class="curr">{{ TOURNAMENTS[index].attributes.participants_count }}</span>
            /
            <span class="max">{{ TOURNAMENTS[index].attributes.count_of_teams }}</span>
          </div>

          <div class="discAndPlat">
            <span class="discipline">{{ TOURNAMENTS[index].game.attributes.name }}</span>
            <!-- TODO ждем платформу с бэка -->
            <!--
            /
            <span class="platform">{{ tournament.platform }}</span>-->
          </div>
        </div>
      </div>
      <div v-if="isPremium((TOURNAMENTS[index]))" class="tournament-card__body">
        <div class="tournament-card__groups">
          <div class="info-block">
            <div class="title">Формат</div>
            <div class="info-string">
              <div class="time">Формат турнира</div>
              <div class="team1">{{ getFormat((TOURNAMENTS[index])) }}</div>
            </div>
            <div class="info-string">
              <div class="time">Формат участия</div>
              <div class="team1">{{ getPlayersFormat((TOURNAMENTS[index])) }}</div>
            </div>
          </div>
          <div class="info-block">
            <div class="title">Расписание</div>
            <div class="info-string">
              <div class="time">Начало регистрации</div>
              <div class="team1">{{ getStartRegTime((TOURNAMENTS[index])) }}</div>
            </div>
            <div class="info-string">
              <div class="time">Окончание регистрации</div>
              <div class="team1">{{ getEndRegTime((TOURNAMENTS[index])) }}</div>
            </div>
            <div class="info-string">
              <div class="time">Начало турнира</div>
              <div class="team1">{{ getStartedTime((TOURNAMENTS[index])) }}</div>
            </div>
          </div>
        </div>
      </div>
      <img v-if="isPremium((TOURNAMENTS[index]))" class="tournament-card__bg" :src="TOURNAMENTS[index].background"
           alt="#">
    </router-link>

    <div class="card-button"
         :class="{disabled: !isUserCanDoIt(TOURNAMENTS[index])}"
         v-if="isUserCanDoIt(TOURNAMENTS[index])">
      <template v-if="!tournamentCardHaveActiveEntry">
        <div @click.stop="participateInTournament(TOURNAMENTS[index], $event)"
             v-if="isUserCanDoIt(TOURNAMENTS[index])"
        >
          <span>Участвовать</span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 2H12.6667C13.0203 2 14 2.00045 14 2.00045C14 2.00045 14 2.97971 14 3.33333V12.6667C14 13.0203 14 14 14 14C14 14 13.0203 14 12.6667 14H10"
                stroke="white" stroke-linejoin="round"/>
            <path d="M6.66663 11.3327L9.99996 7.99935L6.66663 4.66602" stroke="white" stroke-linejoin="round"/>
            <path d="M10 8H2" stroke="white" stroke-linejoin="round"/>
          </svg>
        </div>
      </template>
    </div>

    <tournament-registration
        v-if="isModalOpened"
        :tournament="TOURNAMENTS[index]"
        :composition="currentComposition"
        @closeModal="closeRegModal()"
        @showThanks="showThanks()"
    />
  </div>
</template>

<script>
import getTimezone from "@/otherJS/getTimezone";
import {mapGetters, mapActions} from 'vuex';
import TournamentRegistration from "@/components/modals/tournament-registration/tournament-registration";
import {tournamentInfoMixin} from "@/otherJS/mixins/tournamentInfoMixin";

var momentDurationFormatSetup = require("moment-duration-format");

export default {
  name: "tournamentCard",
  components: {TournamentRegistration},
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'TOURNAMENTS',
      'AUTH',
      'USER',
      'USER_MANAGED_TEAMS',
    ]),
  },
  mixins: [getTimezone, tournamentInfoMixin],
  methods: {
    ...mapActions([
      'CREATE_TOURNAMENT_ENTRIE',
      'IS_ENTRY_CREATED'
    ]),
    closeRegModal() {
      this.isModalOpened = false;
    },
  },
  mounted() {
    this.setIsActiveParticipantEntry(this.TOURNAMENTS[this.index]);
    console.log(this.tournamentCardHaveActiveEntry)
  }
}

</script>

<style lang="scss" scoped>
.tournament-card {
  margin-bottom: 4px;
}
</style>